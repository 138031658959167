import React from 'react'

// import Header from '@/header'

// import Favicons from './favicons'
import './tachyons.min.css'
import './global.css'

const Layout = ({ children, setHash, hash }) => (
  <div className="layout-body flex flex-column min-vh-100 sans-serif dark-gray lh-copy f10">
    {/* <Favicons /> */}
    {/* <Header setHash={setHash} hash={hash} /> */}
    <main className="flex-grow-1 bg-near-white">{children}</main>
  </div>
)

export default Layout
